.loadbox{
    position: fixed;
    top:0;
    left:0;
    z-index:99;
    background: rgba(0,0,0,0.0);
    width: 100%;
    height: 100%;
    img{
        position: absolute;
        z-index:100;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100px;
        height: 100px;
    }
}